<template>
  <el-form
    :model="formData"
    :rules="rules"
    ref="formRef"
    label-width="240px"
    label-suffix="："
  >
    <el-form-item label="企业注册名称" prop="requiredForm">
      <span :class="{ highLight: detailData.companyNameLight }">
        {{ detailData.companyName }}
      </span>
    </el-form-item>
    <el-form-item label="企业证件号码" prop="requiredForm">
      <span :class="{ highLight: detailData.companyCardLight }">
        {{ detailData.companyCard }}
      </span>
    </el-form-item>
    <el-form-item label="公司所在国家" prop="requiredForm">
      <span :class="{ highLight: detailData.countryNameLight }">
        {{ detailData.countryName }}
      </span>
      <!-- <span :class="{ highLight: detailData.cityLight }">
        {{ detailData.cityName }}
      </span>
      <span :class="{ highLight: detailData.countyLight }">
        {{ detailData.countyName }}
      </span>
      <span
        v-if="detailData.address"
        :class="{ highLight: detailData.addressLight }"
      >
        {{ detailData.address }}
      </span> -->
    </el-form-item>
    <!-- <div v-if="!isRealName">
      <el-form-item label="联系人姓名" prop="requiredForm">
        <span :class="{ highLight: detailData.nameLight }">
          {{ detailData.name }}
        </span>
      </el-form-item>
      <el-form-item label="联系人手机" prop="requiredForm">
        <span :class="{ highLight: detailData.relationTelLight }">
          {{ detailData.relationTel }}
        </span>
      </el-form-item>
    </div>
    <div v-if="isRealName">
      <el-form-item label="法人姓名" prop="requiredForm">
        <span :class="{ highLight: detailData.legalNameLight }">
          {{ detailData.legalName }}
        </span>
      </el-form-item>
      <el-form-item label="法人身份证号" prop="requiredForm">
        <span :class="{ highLight: detailData.idcardLight }">
          {{ detailData.idcard }}
        </span>
      </el-form-item>
    </div> -->
    <el-form-item label="企业证件" prop="requiredForm">
      <!-- 显示pdf地址 -->
      <span
        :class="{
          highLight: detailData.identityAdjunctVo
            ? detailData.identityAdjunctVo.companyMergeFileLight
            : '',
        }"
        class="textBtn"
        v-if="
          detailData.identityAdjunctVo &&
          detailData.identityAdjunctVo.companyMergeFile &&
          pdfType(detailData.identityAdjunctVo.companyMergeFile)
        "
        @click="gourl(detailData.identityAdjunctVo.companyMergeFile)"
        >{{ detailData.identityAdjunctVo.companyMergeFile }}</span
      >
      <!-- 显示图片 -->
      <div
        v-if="!pdfType(detailData.identityAdjunctVo.companyMergeFile)"
        :class="{
          width24: true,
          highLight: detailData.identityAdjunctVo
            ? detailData.identityAdjunctVo.companyMergeFileLight
            : '',
        }"
      >
        <!-- <p>营业执照（三合一）</p> -->
        <div
          style="width: 100px; height: 100px; border: 1px solid #999"
          class="mr20 disfr jc ac"
          v-if="
            !detailData.identityAdjunctVo ||
            (!detailData.identityAdjunctVo.personHand &&
              !detailData.identityAdjunctVo.companyMergeFile)
          "
        >
          暂无图片
        </div>
        <el-image
          style="width: 150px"
          class="mr20"
          :src="
            detailData.identityAdjunctVo.personHand ||
            detailData.identityAdjunctVo.companyMergeFile
          "
          v-if="
            detailData.identityAdjunctVo &&
            (detailData.identityAdjunctVo.personHand ||
              detailData.identityAdjunctVo.companyMergeFile)
          "
          :preview-src-list="[
            detailData.identityAdjunctVo.personHand ||
              detailData.identityAdjunctVo.companyMergeFile,
          ]"
        >
        </el-image>
      </div>
      <!-- <div @contextmenu="handleRightMouse" @click="handleClickImage">
        <div class="disfr">
          <div
            :class="{
              width33: true,
              highLight: detailData.identityAdjunctVo
                ? detailData.identityAdjunctVo.companyMergeFileLight
                : '',
            }"
          >
            <p>营业执照（三合一）</p>
            <div
              style="width: 100px; height: 100px"
              class="mr20 disfr jc ac"
              v-if="
                !detailData.identityAdjunctVo ||
                (!detailData.identityAdjunctVo.personHand &&
                  !detailData.identityAdjunctVo.companyMergeFile)
              "
            >
              暂无图片
            </div>
            <el-image
              style="width: 100px"
              class="mr20"
              :src="
                detailData.identityAdjunctVo.personHand ||
                detailData.identityAdjunctVo.companyMergeFile
              "
              v-if="
                detailData.identityAdjunctVo &&
                (detailData.identityAdjunctVo.personHand ||
                  detailData.identityAdjunctVo.companyMergeFile)
              "
              :preview-src-list="[
                detailData.identityAdjunctVo.personHand ||
                  detailData.identityAdjunctVo.companyMergeFile,
              ]"
            >
            </el-image>
          </div>
          <div
            :class="{
              width33: true,
              highLight: detailData.identityAdjunctVo
                ? detailData.identityAdjunctVo.companyOtherOneLight
                : '',
            }"
          >
            <p>其他证件</p>
            <div
              style="width: 100px; height: 100px"
              class="mr20 disfr jc ac"
              v-if="
                !detailData.identityAdjunctVo ||
                !detailData.identityAdjunctVo.companyOtherOne
              "
            >
              暂无图片
            </div>
            <el-image
              style="width: 100px"
              class="mr20"
              :src="detailData.identityAdjunctVo.companyOtherOne"
              v-if="
                detailData.identityAdjunctVo &&
                detailData.identityAdjunctVo.companyOtherOne
              "
              :preview-src-list="[detailData.identityAdjunctVo.companyOtherOne]"
            >
            </el-image>
          </div>
          <div
            :class="{
              width33: true,
              highLight: detailData.identityAdjunctVo
                ? detailData.identityAdjunctVo.companyOtherTwoLight
                : '',
            }"
          >
            <p>其他证件</p>
            <div
              style="width: 100px; height: 100px"
              class="mr20 disfr jc ac"
              v-if="
                !detailData.identityAdjunctVo ||
                !detailData.identityAdjunctVo.companyOtherTwo
              "
            >
              暂无图片
            </div>
            <el-image
              style="width: 100px"
              class="mr20"
              :src="detailData.identityAdjunctVo.companyOtherTwo"
              v-if="
                detailData.identityAdjunctVo &&
                detailData.identityAdjunctVo.companyOtherTwo
              "
              :preview-src-list="[detailData.identityAdjunctVo.companyOtherTwo]"
            >
            </el-image>
          </div>
        </div>
        <div class="disfr">
          <div
            :class="{
              width33: true,
              highLight: detailData.identityAdjunctVo
                ? detailData.identityAdjunctVo.legalPersonJustLight
                : '',
            }"
          >
            <p>法人手持身份证（头像面）</p>
            <div
              style="width: 100px; height: 100px"
              class="mr20 disfr jc ac"
              v-if="
                !detailData.identityAdjunctVo ||
                (!detailData.identityAdjunctVo.personJust &&
                  !detailData.identityAdjunctVo.legalPersonJust)
              "
            >
              暂无图片
            </div>
            <el-image
              class="mr20"
              style="width: 100px"
              :src="
                detailData.identityAdjunctVo.personJust ||
                detailData.identityAdjunctVo.legalPersonJust
              "
              v-if="
                detailData.identityAdjunctVo &&
                (detailData.identityAdjunctVo.personJust ||
                  detailData.identityAdjunctVo.legalPersonJust)
              "
              :preview-src-list="[
                detailData.identityAdjunctVo.personJust ||
                  detailData.identityAdjunctVo.legalPersonJust,
              ]"
            >
            </el-image>
          </div>
          <div
            :class="{
              width33: true,
              highLight: detailData.identityAdjunctVo
                ? detailData.identityAdjunctVo.legalPersonBackLight
                : '',
            }"
          >
            <p>法人手持身份证（国徽面）</p>
            <div
              style="width: 100px; height: 100px"
              class="mr20 disfr jc ac"
              v-if="
                !detailData.identityAdjunctVo ||
                (!detailData.identityAdjunctVo.personBack &&
                  !detailData.identityAdjunctVo.legalPersonBack)
              "
            >
              暂无图片
            </div>
            <el-image
              class="mr20"
              style="width: 100px"
              :src="
                detailData.identityAdjunctVo.personBack ||
                detailData.identityAdjunctVo.legalPersonBack
              "
              v-if="
                detailData.identityAdjunctVo &&
                (detailData.identityAdjunctVo.personBack ||
                  detailData.identityAdjunctVo.legalPersonBack)
              "
              :preview-src-list="[
                detailData.identityAdjunctVo.personBack ||
                  detailData.identityAdjunctVo.legalPersonBack,
              ]"
            >
            </el-image>
          </div>
        </div>
      </div> -->
    </el-form-item>
    <el-form-item label="开发者官网地址" v-if="isShowUrl">
      <span
        :class="{ highLight: detailData.websiteUrlLight }"
        class="textBtn"
        @click="gourl(detailData.websiteUrl)"
      >
        {{ detailData.websiteUrl }}
      </span>
    </el-form-item>
  </el-form>
</template>
<script>
import { pdfType } from "@/common/utils/utils.js";
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    imgList: {
      type: Array,
      default() {
        return [];
      },
    },
    isRealName: {
      //true实名认证审核页面 false开发者列表页面
      type: Boolean,
      default() {
        return false;
      },
    },
    isShowUrl: {
      //true开发者官网地址显示 false开发者官网地址不显示
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      pdfType,
      formData: {
        requiredForm: 1, // 用于显示必输的红色星号，无业务上的意义
      },
      rules: {
        requiredForm: [
          {
            required: true,
            message: "该信息为必输信息",
          },
        ],
      },
    };
  },
  created() {
    let sur =
      "https://newmarket4.tt286.com/market_develop/other/0273069480_1698401080489.png.pdf";
    // console.log(this.pdfType(sur));
  },
  methods: {
    gourl(url) {
      window.open(url, "_blank");
    },
    // 禁用鼠标右键
    handleRightMouse(e) {
      e.preventDefault();
    },
    // 禁用图片预览的dom的右键事件
    handleClickImage() {
      this.$nextTick(() => {
        const el = document.querySelector(".el-image-viewer__wrapper");
        if (el) {
          el.oncontextmenu = function () {
            return false;
          };
        }
      });
    },
  },
};
</script>
<style scoped>
.width33 {
  width: 33%;
  margin-top: 20px;
  margin-right: 5px;
}
.width24 {
  width: 24%;
  margin-top: 20px;
  margin-right: 5px;
  /* padding: 10px; */
}
</style>
