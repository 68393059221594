import request from "@/config/serve.js";

// 查询
export function getVerifiedCheckList(params) {
  return request("post", "/developerUser/getDeveloperUserIdentityList", params);
}
// 实名认证审核
export function updateStatusById(params) {
  return request("post", "/developerUser/updateStatusById", params);
}
// 实名认证详情
export function getDetailsByIdDiff(params) {
  return request("post", "/developerUser/getDetailsByIdDiff", params);
}
// // 判断是否为新用户
// export function getIsNewUser(params) {
//   return request("get", "/developerUser/newUser", params);
// }
