<template>
  <el-form
    :model="formData"
    :rules="rules"
    ref="formRef"
    label-width="120px"
    label-suffix="："
  >
    <el-form-item label="审核结果" prop="status">
      <el-radio-group
        v-model="formData.status"
        @change="$refs.formRef.clearValidate()"
      >
        <el-radio :label="3">通过</el-radio>
        <el-radio :label="4">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="审核意见"
      prop="remark"
      :rules="[{ required: formData.status == 4, message: '请输入审核意见' }]"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 6 }"
        show-word-limit
        maxlength="100"
        v-model="formData.remark"
        suffix-icon="el-icon-date"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    checkData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      formData: { status: "", remark: "" },
      rules: {
        status: [
          { required: true, message: "请选择审核结果", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    init() {
      this.formData = {
        status: 3,
        remark: "",
      };
      this.$refs.formRef.clearValidate();
    },
    handleSubmitAudit() {
      const data = {
        ...this.formData,
        backendType: 1,
      };
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$emit("submit", data);
        }
      });
    },
  },
  created() {
    this.init(this.checkData);
  },
  watch: {
    checkData(data) {
      this.init(data);
    },
  },
};
</script>
<style lang="scss" scoped></style>
