import request from "@/config/serve.js";
import { MAXSIZE } from "@/common/constant/constant.js";

export function getLanguageList(params) {
  return request("get", "/language/all", {
    page: 1,
    size: MAXSIZE,
    ...params,
  });
}
// 查询预约管理
export function appointmentManage(params) {
  return request("post", "/appReserved/reservedApplyList", params);
}

//预约下架审核
export function outReviewInfo(params) {
  return request("post", "/app/outReviewInfo", params);
}
//预约审核
export function appointmentAudit(params) {
  return request("post", "/app/ReservedReviewInfo", params);
}
//预约审核 批量
export function appointmentAuditList(params) {
  return request("post", "/app/batchReviewInfo", params);
}
//预约 查看预约信息 Y
export function lookAppointmentInfo(params) {
  return request("get", "/app/selectReservedApply", params);
}
// 查询审核人员列表,type:页面（1信息审核列表，3预约审核列表，11预下架审核）
export function getAuditUsers(params) {
  return request(
    "get",
    "/app/queryAuditUsers",
    {
      type: 3,
      ...params,
    },
    false
  );
}

// 查询国家、地区 Y
export function getAreaList(params) {
  return request("get", "/app/selectCountry", {
    page: 1,
    size: MAXSIZE,
    ...params,
  });
}
// 查询国家、地区 Y 出价阈值用到
export function countriesPromotionList(params) {
  return request("post", "/countriesPromotion/list", {
    page: 1,
    size: MAXSIZE,
    ...params,
  });
}
//内测管理列表
export function closedBetaList(params) {
  return request("post", "/appTest/queryTestList", params);
}
//内测管理 查看内测信息 Y
export function lookcloseBetaInfo(params) {
  return request("get", "/app/selectTestInfo", params);
}
//内测审核 Y
export function closeBetaAudit(params) {
  return request("post", "/app/TestReviewInfo", params);
}
//内测审核 批量
export function closeBetaAuditList(params) {
  return request("post", "/app/batchReviewTestInfo", params);
}

//首发列表  Y
export function startIngList(params) {
  return request("post", "/honorAppPrioritizedApply/prioritizedList", params);
}
//首发审核 Y
export function startAudit(params) {
  return request("post", "/honorAppPrioritizedApply/review", params);
}
//内测首发管理 查看首发信息 Y
export function lookstartingInfo(params) {
  return request("post", "/honorAppPrioritizedApply/detail", params);
}

// 查询角色列表 Y
export function getRoleList(params) {
  return request("post", "/role/queryList", params);
}
// 获取省市区
export function getCityList(params) {
  return request("get", "/apiGo/api/v1/app/manage/getRegionList", params);
}
// 获取开发者列表
export function getDeveloperUser(params) {
  return request("post", `/developerUser/getDeveloperUserList`, {
    ...params,
    identityType: 1,
  });
}
// 推广 创意
export function getIdeaAuditList(params) {
  return request("post", `/apiGo/api/v1/app/manage/getIdeaAuditList`, params);
}
export function getIdeaLog(params) {
  //历史记录
  return request("post", `/apiGo/api/v1/app/manage/getIdeaAuditLog`, params);
}
export function ideaAuditExec(params) {
  //审核
  return request("post", `/apiGo/api/v1/app/manage/ideaAuditExec`, params);
}
//CPD消耗报表统计
export function getTotalReportSum(params) {
  // return request("post", `/v1/app/manage/promote/getTotalReportSum`, params);
  return request("post", `/report/xh/reportStatistics`, params);
}
//CPD消耗报表明细
export function queryTotalReportList(params) {
  // return request("post", `/v1/app/manage/promote/queryTotalReportList`, params);
  return request("post", `/report/cpdxh/reportList`, params);
}
//所有应用列表（投放报表中用到）
export function getAllAppStatementList(params) {
  // return request("get", "/v1/developerUser/getAllAppList", params);
  return request("post", "/app/appInfoList", params);
}
//所有开发者列表
export function getDeveloperUserList(params) {
  return request("get", "/developerTaskReport/getDeveloperUserList", params);
}
// 推广组列表
export function getTaskPloyNameList(params) {
  // return request("post", `/v1/app/manage/getTaskPloyNameList`, params);
  return request("post", `/report/groupList`, params);
}
// 创意列表
export function getideaListList(params) {
  return request("post", `/report/ideaList`, params);
}
export function getIdeaNameList(params) {
  return request("post", `/v1/app/manage/getIdeaNameList`, params);
}
// 推广计划
export function getPromoteTaskNameList(params) {
  // return request("post", `/v1/app/manage/getPromoteTaskNameList`, params);
  return request("post", `/report/planList`, params);
}
//报表模块
//获取商务列表
export function getBusinessList(params) {
  // return request("get", "/v1/app/manage/promoteReport/getBusinessList", params);
  return request("post", "/honorManageUser/businessList", params);
}
// 投放报表
export function getTaskReportSum(params) {
  return request("post", "/report/tf/reportStatistics", params);
}
export function queryTaskReportList(params) {
  return request("post", `/report/tf/reportList`, params);
}
//获取国家列表
export function cmsCountryAll(params) {
  return request("get", "/report/countries", params);
}
//商务人员管理列表
export function getBusinessManagerList(params) {
  return request("post", "/honorManageUser/businessManage", params);
}
